import { Box, Drawer, List, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetAssignedTicketsQuery } from 'src/common/apis';
import useResponsive from 'src/common/hooks/useResponsive';

import Icon from '../Icon';
import { ListItemStyle } from './styles';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  role: string | null;
};

const Navbar = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { uid } = useParams();
  const { data: myTickets } = useGetAssignedTicketsQuery();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <Stack
        spacing={3}
        sx={{
          py: 3,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon name="logo" width={152} height={44} />
        </Stack>
      </Stack>
      <Box>
        <List disablePadding>
          {myTickets?.data.items.map((ticket) => {
            const active = !uid ? false : uid === ticket.uid;

            return (
              <ListItemStyle
                key={ticket.uid}
                active={active}
                onClick={() => navigate(`/ticket/${ticket.uid}`)}
              >
                <Typography variant={active ? 'subtitle2' : 'body2'}>
                  {ticket.client.ClientProfile
                    ? `${ticket.client.ClientProfile.firstName} ${ticket.client.ClientProfile.lastName}`
                    : ticket.client.User.email}
                </Typography>
                <Typography variant={active ? 'subtitle2' : 'body2'}>
                  {ticket.supportTag.name}
                </Typography>
              </ListItemStyle>
            );
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: 350 } }}
        >
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 350,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export default Navbar;
