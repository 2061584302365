import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import InsecureZone from 'src/common/components/InsecureZone';
import SecureZone from 'src/common/components/SecureZone';

import NotFound from './common/components/NotFound';

const Dashboard = lazy(() => import('./dashboard'));
const SignIn = lazy(() => import('./auth/SignIn'));
const AcceptInvitation = lazy(() => import('./auth/AcceptInvitation'));
const TicketDetails = lazy(() => import('./ticketDetails'));

function App() {
  return (
    <Routes>
      <Route path="/" element={<SecureZone />}>
        <Route index element={<Dashboard />} />
        <Route path="/ticket/:uid" element={<TicketDetails />} />
        <Route path="/404" element={<NotFound />} />
      </Route>

      <Route element={<InsecureZone />}>
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/register/:token" element={<AcceptInvitation />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
