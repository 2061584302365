export const authApiUrl = process.env.REACT_APP_AUTH_API_URL || '';
export const mainApiUrl = process.env.REACT_APP_USER_API_URL || '';
export const sseApiUrl = process.env.REACT_APP_SSE_API_URL || '';

export const legalDocumentsManagementTag = 'legal_documents_management';
export const customContractManagementTag = 'custom_contract_management';
export const legalEntitiesNotificationTag = 'legal_entities_notification';
export const employerDataVerificationTag = 'employer_data_verification';
export const financialQuestionsTag = 'financial_questions';
export const platformQuestionsTag = 'platform_questions';
export const legalisationQuestionsTag = 'legalisation_questions';
export const generalQuestionsTag = 'general_questions';
