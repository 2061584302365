import { Box, GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { useRef } from 'react';

import { ColorSchema } from '../../theme/palette';
import Icon, { IconName } from '../Icon';

type NotificationsProviderProps = {
  children: React.ReactNode;
};

function SnackbarStyles() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1.5),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[0],
            backgroundColor: theme.palette.grey[900],
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const notistackRef = useRef<SnackbarProvider>(null);

  const onClose = (key: SnackbarKey) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={3}
        autoHideDuration={3000}
        variant="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="alertInfo" color="info" />,
          success: <SnackbarIcon icon="alertSuccess" color="success" />,
          warning: <SnackbarIcon icon="alertWarning" color="warning" />,
          error: <SnackbarIcon icon="alertError" color="error" />,
        }}
        action={(key) => (
          <Box
            onClick={onClose(key)}
            display="flex"
            alignItems="center"
            sx={{ p: 0.5, cursor: 'pointer' }}
          >
            <Icon name="close" height="20px" />
          </Box>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};

type SnackbarIconProps = {
  icon: IconName;
  color: ColorSchema;
};

const SnackbarIcon = ({ icon, color }: SnackbarIconProps) => {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Icon name={icon} color={`${color}.main`} />
    </Box>
  );
};

export default NotificationsProvider;
