import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  AlertError,
  AlertInfo,
  AlertSuccess,
  AlertWarning,
  AttachIcon,
  BelarussianFlagIcon,
  CheckboxCheckedIcon,
  CheckboxIcon,
  CheckboxIndeterminateIcon,
  CloseIcon,
  DownloadIcon,
  EnglishFlagIcon,
  FinanceIcon,
  KewstonLogo,
  PolishFlagIcon,
  RussianFlagIcon,
  SendMessageIcon,
  SidebarCollapse,
  UkranianFlagIcon,
} from './Icons';

export type IconName =
  | 'logo'
  | 'finance'
  | 'russianFlag'
  | 'englishFlag'
  | 'polishFlag'
  | 'ukranianFlag'
  | 'belarussianFlag'
  | 'alertError'
  | 'alertWarning'
  | 'alertSuccess'
  | 'alertInfo'
  | 'sidebarCollapse'
  | 'checkbox'
  | 'checkboxChecked'
  | 'checkboxIndeterminate'
  | 'sendMessage'
  | 'attach'
  | 'download'
  | 'close';

const IconStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { fill: 'currentColor' },
});

interface Props extends BoxProps {
  name: IconName;
  color?: string;
}

const IconMap: Record<IconName, JSX.Element> = {
  logo: <KewstonLogo />,
  finance: <FinanceIcon />,
  russianFlag: <RussianFlagIcon />,
  englishFlag: <EnglishFlagIcon />,
  polishFlag: <PolishFlagIcon />,
  ukranianFlag: <UkranianFlagIcon />,
  belarussianFlag: <BelarussianFlagIcon />,
  alertError: <AlertError />,
  alertWarning: <AlertWarning />,
  alertSuccess: <AlertSuccess />,
  alertInfo: <AlertInfo />,
  close: <CloseIcon />,
  sidebarCollapse: <SidebarCollapse />,
  checkbox: <CheckboxIcon />,
  sendMessage: <SendMessageIcon />,
  attach: <AttachIcon />,
  checkboxChecked: <CheckboxCheckedIcon />,
  checkboxIndeterminate: <CheckboxIndeterminateIcon />,
  download: <DownloadIcon />,
};

// eslint-disable-next-line react/display-name
const Icon = ({ name, color, ...rest }: Props) => {
  return (
    <IconStyled
      component="span"
      width="22px"
      height="22px"
      display="inline-block"
      color={color || 'currentColor'}
      {...rest}
    >
      {IconMap[name]}
    </IconStyled>
  );
};

export default Icon;
