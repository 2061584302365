import { Theme } from '@mui/material/styles';

import Alert from './Alert';
import Button from './Button';
import Checkbox from './Checkbox';
import ControlLabel from './ControlLabel';
import CssBaseline from './CssBaseline';
import Dialog from './Dialog';
import Input from './Input';
import Link from './Link';
import Pagination from './Pagination';
import Radio from './Radio';
import Select from './Select';
import Stepper from './Stepper';
import Switch from './Switch';
import Table from './Table';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Input(theme),
    CssBaseline(),
    ControlLabel(theme),
    Link(),
    Dialog(theme),
    Table(theme),
    Pagination(theme),
    Select(),
    Alert(theme),
    Switch(theme),
    Checkbox(theme),
    Stepper(theme),
    Radio(theme),
  );
}
