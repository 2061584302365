import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { authApiUrl, mainApiUrl } from 'src/config';

import { UploadFile } from './components/Upload/Upload.types';
import type { ApiError, PaginatedData, SupportTicketBase } from './types';

export interface UserState {
  email: string;
  role: string;
  uid: string;
  mfaEnabled: boolean;
}

export const commonApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: authApiUrl }),
  reducerPath: 'commonApi',
  tagTypes: ['AssignedTasks'],
  endpoints: (build) => ({
    getUser: build.query<UserState, void>({
      query() {
        return {
          url: '/current-user',
          method: 'GET',
        };
      },
    }),
    getSSEOneTimeToken: build.query<{ token: string }, void>({
      query() {
        return {
          url: `${authApiUrl}/token/one-time`,
          method: 'POST',
        };
      },
    }),
    uploadFile: build.mutation<string, UploadFile>({
      async queryFn(file) {
        try {
          const uploadInfo = await axios.post(
            `${mainApiUrl}/file-upload/request`,
            {
              originalName: file.content.name,
              extension: file.content.name.split('.').at(-1),
              mimetype: file.content.type,
            },
          );

          await axios.put(uploadInfo.data.data.url, file.content, {
            headers: { 'Content-Type': file.content.type },
            transformRequest: (data, headers) => {
              delete headers?.['Authorization'];
              return data;
            },
          });

          await axios.post(`${mainApiUrl}/file-upload/confirm`, {
            size: file.content.size,
            uid: uploadInfo.data.data.uid,
          });

          return {
            data: uploadInfo.data.data.uid,
          };
        } catch (err) {
          return { error: err as ApiError };
        }
      },
    }),
    getAssignedTickets: build.query<PaginatedData<SupportTicketBase>, void>({
      providesTags: ['AssignedTasks'],
      query() {
        return {
          url: `${mainApiUrl}/support-case/list`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useGetAssignedTicketsQuery,
  useLazyGetSSEOneTimeTokenQuery,
  useUploadFileMutation,
} = commonApi;
