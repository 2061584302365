import { Theme } from '@mui/material/styles';
import {
  AlertError,
  AlertInfo,
  AlertSuccess,
  AlertWarning,
} from 'src/common/components/Icon/Icons';

import { ColorSchema } from '../palette';

export default function Alert(theme: Theme) {
  const standardStyle = (color: ColorSchema) => ({
    color: theme.palette[color]['darker'],
    backgroundColor: theme.palette[color]['lighter'],
    '& .MuiAlert-icon': {
      color: theme.palette[color]['main'],
    },
  });

  const filledStyle = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
  });

  const outlinedStyle = (color: ColorSchema) => ({
    color: theme.palette[color]['darker'],
    border: `solid 1px ${theme.palette[color]['light']}`,
    backgroundColor: theme.palette[color]['lighter'],
    '& .MuiAlert-icon': {
      color: theme.palette[color]['main'],
    },
  });

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <AlertInfo />,
          success: <AlertSuccess />,
          warning: <AlertWarning />,
          error: <AlertError />,
        },
      },

      styleOverrides: {
        message: {
          '& .MuiAlertTitle-root': {
            marginBottom: theme.spacing(0.5),
          },
        },
        action: {
          paddingTop: 0,
          alignItems: 'center',
          button: {
            borderColor: 'inherit',
            textTransform: 'none',
          },
          '& button:not(:first-of-type)': {
            marginLeft: theme.spacing(1),
          },
        },

        standardInfo: standardStyle('info'),
        standardSuccess: standardStyle('success'),
        standardWarning: standardStyle('warning'),
        standardError: standardStyle('error'),

        filledInfo: filledStyle('info'),
        filledSuccess: filledStyle('success'),
        filledWarning: filledStyle('warning'),
        filledError: filledStyle('error'),

        outlinedInfo: outlinedStyle('info'),
        outlinedSuccess: outlinedStyle('success'),
        outlinedWarning: outlinedStyle('warning'),
        outlinedError: outlinedStyle('error'),
      },
    },
  };
}
