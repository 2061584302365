import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Divider, MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from 'src/common/services/authService';

import MenuPopover from '../../MenuPopover';
import MyAvatar from './MyAvatar';

type AccountPopoverProps = {
  email: string;
};

const AccountPopover = ({ email }: AccountPopoverProps) => {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const theme = useTheme();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        display="flex"
        alignItems="center"
        bgcolor={theme.palette.grey[500_8]}
        px="20px"
        py={1}
        borderRadius="12px"
        sx={{
          cursor: 'pointer',
        }}
      >
        <MyAvatar displayName={email} />
        <Box
          typography="subtitle2"
          color={theme.palette.text.primary}
          ml={2}
          mr={1}
        >
          {email}
        </Box>
        {open ? (
          <ExpandLess htmlColor={theme.palette.text.secondary} />
        ) : (
          <ExpandMore htmlColor={theme.palette.text.secondary} />
        )}
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow="top-center"
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem to="/settings" component={RouterLink} onClick={handleClose}>
            Settings
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => logout()} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
