import { useEffect, useRef } from 'react';

import { sseApiUrl } from '../../config';
import { useLazyGetSSEOneTimeTokenQuery } from '../apis';
import {
  addLogoutListener,
  removeLogoutListener,
} from '../services/authService';

export default function useSSE(appLoaded: boolean) {
  const eventSource = useRef<EventSource | null>(null);
  const reconnectionTryCountRef = useRef(0);
  const [getOneTimeToken] = useLazyGetSSEOneTimeTokenQuery();

  const disconnect = () => {
    eventSource.current?.close();
    console.log('SSE closed!');
  };

  const getEventSource = async () => {
    const authTokenResponse = await getOneTimeToken().unwrap();
    const authToken = authTokenResponse?.token;

    if (!authToken) {
      throw new Error('One time token access was unsuccessful');
    }

    return new EventSource(`${sseApiUrl}/user?authorization=${authToken}`);
  };

  const connect = async () => {
    const source = await getEventSource();

    source.addEventListener('open', () => {
      reconnectionTryCountRef.current = 0;
      console.log('SSE opened!');
      addLogoutListener(disconnect);
    });

    source.addEventListener('error', (event) => {
      if (event.type === 'error') {
        console.log('SSE error!', JSON.stringify(event));

        setTimeout(() => {
          if (reconnectionTryCountRef.current < 10) {
            disconnect();
            connect();
            reconnectionTryCountRef.current += 1;
          }
        }, (reconnectionTryCountRef.current + 1) * 1000);
      }
    });

    eventSource.current = source;

    return source;
  };

  useEffect(() => {
    if (appLoaded && !eventSource.current) {
      connect().then((source) => {
        source.addEventListener('support_case_new_message', (e) => {
          console.log(e.data);
        });
      });
    }

    return () => {
      removeLogoutListener(disconnect);
    };
  }, [appLoaded]);

  return eventSource.current;
}
