import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLazyGetUserQuery } from 'src/common/apis';
import { SSEContext } from 'src/common/contexts/SSEContext';
import { useAppSelector } from 'src/common/hooks/reduxHooks';
import {
  redirectToLogin,
  requestInterceptor,
  responseErrorInterceptor,
} from 'src/common/services/authService';

import useSSE from '../../hooks/useSSE';
import Navbar from '../Navbar';
import SideBar from '../SideBar';

type MainStyleProps = {
  collapseClick?: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: 88,
  paddingBottom: 88,
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 100,
    paddingBottom: 20,
    width: 'calc(100% - 350px)',
    marginLeft: 350,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: 88,
    }),
  },
}));

const SecureZone = () => {
  const [isLoading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [getUser] = useLazyGetUserQuery();
  const eventSource = useSSE(!isLoading);
  const { role } = useAppSelector((state) => state.common);
  console.log('render SecureZone');

  const authenticate = async () => {
    axios.interceptors.request.use(requestInterceptor);
    axios.interceptors.response.use(
      (response) => response,
      responseErrorInterceptor,
    );

    await getUser().unwrap();
  };

  useEffect(() => {
    authenticate()
      .then(() => setLoading(false))
      .catch((err) => {
        console.error(err);
        redirectToLogin();
      });
  }, []);

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  if (isLoading) return <div>Loading</div>;

  return (
    <SSEContext.Provider value={eventSource}>
      <SideBar
        role={role}
        isOpenSidebar={sidebarOpen}
        onCloseSidebar={handleSidebarClose}
      />
      <Navbar onOpenSidebar={handleSidebarOpen} />
      <MainStyle>
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </MainStyle>
    </SSEContext.Provider>
  );
};

export default SecureZone;
