import createAvatar from 'src/common/utils/createAvatar';

import Avatar, { Props as AvatarProps } from '../../Avatar';

type MyAvatarProps = {
  displayName: string;
  imageUrl?: string;
  size?: 'small' | 'normal';
} & AvatarProps;

export default function MyAvatar({
  displayName,
  imageUrl,
  size = 'normal',
  ...other
}: MyAvatarProps) {
  return (
    <Avatar
      src={imageUrl}
      alt={displayName}
      color={imageUrl ? 'default' : createAvatar(displayName).color}
      {...(size === 'small' && {
        sx: { width: '28px', height: '28px' },
      })}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
}
