import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { PaginatedData, SupportTicketBase } from '../common/types';

export const dashboardApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'dashboardApi',
  tagTypes: ['TicketPool'],
  endpoints: (build) => ({
    getTicketPool: build.query<PaginatedData<SupportTicketBase>, null>({
      providesTags: ['TicketPool'],
      query() {
        return {
          url: '/support-case/pool',
          method: 'GET',
        };
      },
    }),
    markCaseRead: build.mutation<void, string>({
      invalidatesTags: ['TicketPool'],
      query(uid) {
        return {
          url: `/support-case/mark-read/${uid}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useGetTicketPoolQuery } = dashboardApi;
