import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

const theme = createTheme({
  shadows,
  customShadows,
  palette,
  typography,
  breakpoints,
  shape: { borderRadius: 8 },
});

theme.components = componentsOverride(theme);

export default theme;
