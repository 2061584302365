import { createSlice } from '@reduxjs/toolkit';

import { commonApi, UserState } from './apis';

const initialState: UserState = {
  email: '',
  role: '',
  uid: '',
  mfaEnabled: false,
};

export const user = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      commonApi.endpoints.getUser.matchFulfilled,
      (state, action) => {
        state.email = action.payload.email;
        state.role = action.payload.role;
        state.mfaEnabled = action.payload.mfaEnabled;
        state.uid = action.payload.uid;
      },
    );
  },
});

export default user.reducer;
