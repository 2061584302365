import { Box, CircularProgress, Grid } from '@mui/material';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Icon from '../Icon';

const InsecureZone = () => {
  const renderLoadingIndicator = () => (
    <Box
      right={0}
      bottom={0}
      zIndex={9999}
      width="100%"
      height="100%"
      position="fixed"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" />
    </Box>
  );

  return (
    <>
      <Grid
        container
        height="100vh"
        alignContent="center"
        justifyContent="center"
        px={2}
      >
        <Grid item xs={12} md={6} lg={4} xl={2.5}>
          <Box display="flex" justifyContent="center" mb={8}>
            <Icon name="logo" width={217} height={60} />
          </Box>
          <Suspense fallback={renderLoadingIndicator()}>
            <Outlet />
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default InsecureZone;
