import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit';

import { authApi } from '../auth/auth.service';
import { dashboardApi } from '../dashboard/dashboard.service';
import { ticketDetailsApi } from '../ticketDetails/ticketDetails.service';
import { commonApi } from './apis';
import commonSlices from './slices';
import SnackBarUtils from './utils/snackbarUtils';

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    SnackBarUtils.error(action.payload.code);
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [commonApi.reducerPath]: commonApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [ticketDetailsApi.reducerPath]: ticketDetailsApi.reducer,
    common: commonSlices,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkQueryErrorLogger,
      commonApi.middleware,
      authApi.middleware,
      dashboardApi.middleware,
      ticketDetailsApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
