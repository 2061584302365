import {
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  ListItemTextProps,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export interface ListItemStyleProps extends ListItemButtonProps {
  active: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListItemStyleProps>(({ active, theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  // Active item
  ...(active && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  }),
}));

interface ListItemTextStyleProps extends ListItemTextProps {
  isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText)<ListItemTextStyleProps>(
  ({ theme }) => ({
    transition: theme.transitions.create(['width', 'opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
  }),
);
