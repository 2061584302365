import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from 'src/common/hooks/reduxHooks';
import useOffSetTop from 'src/common/hooks/useOffsetTop';
import useResponsive from 'src/common/hooks/useResponsive';
import cssStyles from 'src/common/utils/cssStyles';

import Icon from '../Icon';
import AccountPopover from './components/AccountPopover';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset',
})<RootStyleProps>(({ isCollapse, isOffset, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: 64,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: 92,
    width: 'calc(100% - 281px)',
    ...(isCollapse && {
      width: 'calc(100% - 88px)',
    }),
    ...(isOffset && {
      height: 60,
    }),
  },
}));

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
};

const Navbar = ({ onOpenSidebar, isCollapse = false }: Props) => {
  const { email } = useAppSelector((state) => state.common);
  const isOffset = useOffSetTop(92);

  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {!isDesktop && (
          <Button onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon name="sidebarCollapse" width={30} height={30} />
          </Button>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Button
            variant="contained"
            size="large"
            to="/"
            component={RouterLink}
          >
            Get open ticket →
          </Button>
          <AccountPopover email={email} />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
};

export default memo(Navbar);
