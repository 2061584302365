import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

// eslint-disable-next-line react/display-name
export const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(({ href, ...other }, ref) => (
  <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
));
