import { LinkBehavior } from 'src/common/components/LinkBehavior';

export default function Link() {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        component: LinkBehavior,
      },
    },
  };
}
