import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import type { SupportTicketBase } from '../common/types';

export type FileUploadOnSupportCase = {
  fileUpload: {
    extension: string;
    key: string;
    mimetype: string;
    originalName: string;
    uid: string;
  };
};

export type MessageType =
  | 'COMMON'
  | 'ATTACHMENTS'
  | 'DOCUMENTS'
  | 'TEXT'
  | 'TEXT_WITH_ATTACHMENTS'
  | 'TEXT_WITH_DOCUMENTS';

export type ChatMessageAttachment = {
  fileUpload: {
    extension: string;
    key: string;
    mimetype: string;
    originalName: string;
    uid: string;
  };
};

export type ClientResponse = {
  email: string;
  employerAgreementSigned: boolean;
  legalName: string;
  registrationAddressCity: string;
  registrationAddressCountry: {
    name: string;
    uid: string;
  };
  deleted: boolean;
  verified: boolean;
  registrationAddressLine: string;
  registrationAddressLine2: string;
  registrationAddressZip: string;
  taxId: string;
  type: 'INDIVIDUAL_ENTREPRENEUR' | 'COMPANY';
  uid: string;
  hasEmployerAgreement: boolean;
};

type SupportMember = {
  firstName: string;
  lastName: string;
  uid: string;
};

export type SupportChatMessage = {
  client: {
    ClientProfile: {
      firstName: string;
      lastName: string;
    } | null;
    uid: string;
    User: {
      email: string;
    };
  } | null;
  uid: string;
  type: MessageType;
  text: string;
  owner: 'SUPPORT' | 'CLIENT' | 'SYSTEM';
  sentAt: string;
  createdAt: string;
  DocumentsOnSupportChatMessages: [];
  FileUploadsOnSupportChatMessages: Array<ChatMessageAttachment>;
  supportMember: null | SupportMember;
};

export type TicketDetails = {
  data: SupportTicketBase & {
    SupportChatMessage: Array<SupportChatMessage>;
    FileUploadsOnSupportCases: Array<FileUploadOnSupportCase>;
  };
};

type UpdateResidencePermitRequest = {
  clientUid: string;
  residencePermitStatus: 'VERIFIED' | 'DECLINED';
  residencePermitUid: string;
  source: 'INTERNAL' | 'EXTERNAL';
  activeUntil: string;
  fileUploadUidList: Array<string>;
};

type UpdateWorkPermitRequest = {
  clientUid: string;
  workPermitStatus: 'VERIFIED' | 'DECLINED';
  source: 'INTERNAL' | 'EXTERNAL';
  workPermitUid: string;
  activeUntil: string;
  fileUploadUidList: Array<string>;
};

type TicketMessagePayload = {
  supportCaseUid: string;
  text: string;
};

type PossiblePermitsResponse = {
  FileUploadsOnResidencePermits: [];
  activeUntil: null;
  declineReasonCode: null;
  source: string;
  status: string;
  uid: string;
};

export const ticketDetailsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'ticketDetailsApi',
  tagTypes: ['Ticket', 'ClientDetails'],
  endpoints: (build) => ({
    getTicketByUid: build.query<TicketDetails, string>({
      providesTags: ['Ticket'],
      query(uid) {
        return {
          url: `/support-case/${uid}`,
          method: 'GET',
        };
      },
    }),
    getPreviewUrl: build.query<{ data: { url: string } }, string>({
      query(fileUid) {
        return {
          url: `/file-upload/view-url/${fileUid}`,
          method: 'GET',
        };
      },
    }),
    pickUpTicket: build.mutation<void, string>({
      invalidatesTags: ['Ticket'],
      query(uid) {
        return {
          url: `/support-case/start-processing/${uid}`,
          method: 'POST',
        };
      },
    }),
    approveEmployer: build.mutation<
      void,
      { employerUid: string; ticketUid: string }
    >({
      invalidatesTags: ['ClientDetails'],
      query(data) {
        return {
          url: `/organization-employer/verify/${data.employerUid}`,
          method: 'POST',
          data: {
            supportCaseUid: data.ticketUid,
          },
        };
      },
    }),
    getEmployerDetails: build.query<
      { data: ClientResponse },
      { employerUid: string; caseUid: string }
    >({
      providesTags: ['ClientDetails'],
      query({ employerUid, caseUid }) {
        return {
          url: `/organization-employer/${employerUid}?includeDeleted=true&supportCaseUid=${caseUid}`,
          method: 'GET',
        };
      },
    }),
    sendMessage: build.mutation<void, TicketMessagePayload>({
      invalidatesTags: ['Ticket'],
      query(params) {
        return {
          url: '/support-chat-message',
          method: 'POST',
          data: {
            ...params,
            sentAt: new Date(),
            type: 'TEXT',
          },
        };
      },
    }),
    updateWorkPermit: build.mutation<void, UpdateWorkPermitRequest>({
      query(data) {
        return {
          url: '/work-permit/update-status',
          method: 'PATCH',
          data,
        };
      },
    }),
    updateResidencePermit: build.mutation<void, UpdateResidencePermitRequest>({
      query(data) {
        return {
          url: '/residence-permit/update-status',
          method: 'PATCH',
          data,
        };
      },
    }),
    getPossibleWorkPermits: build.query<
      { data: Array<PossiblePermitsResponse> },
      string
    >({
      query(clientUid) {
        return {
          url: `/work-permit/list/${clientUid}`,
          method: 'GET',
        };
      },
    }),
    getPossibleResidencePermits: build.query<
      { data: Array<PossiblePermitsResponse> },
      string
    >({
      query(clientUid) {
        return {
          url: `/residence-permit/list/${clientUid}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetTicketByUidQuery,
  usePickUpTicketMutation,
  useLazyGetPreviewUrlQuery,
  useUpdateWorkPermitMutation,
  useUpdateResidencePermitMutation,
  useSendMessageMutation,
  useApproveEmployerMutation,
  useGetEmployerDetailsQuery,
  useGetPossibleWorkPermitsQuery,
  useGetPossibleResidencePermitsQuery,
} = ticketDetailsApi;
