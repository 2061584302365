import { Theme } from '@mui/material/styles';

export default function Stepper(theme: Theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeftColor: theme.palette.divider,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: 26,
        },
      },
    },
  };
}
